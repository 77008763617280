/* @import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700;800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo:wght@400&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0 text-[14px];
  font-family: "Poppins";
  width: 100vw;
  /* font-size: 14px;  */
}
*,
::before,
::after {
  border-width: 0;
}

.custom-row:hover {
  background-color: rgb(255, 225, 231) !important; /* Change background color on hover */
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 5px;
}

::-webkit-scrollbar-track {
  background:  #e6e6e6; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background:#9c9c9c; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Round the corners of the scrollbar thumb */
}


::-webkit-scrollbar-thumb:hover {
  background: #7e7e7e; /* Color of the scrollbar thumb when hovered */
}
.custom-placeholder::placeholder {
  color: #999;
  text-align: center; 
  font-family: 'Poppins', sans-serif;
  font-size: 14px; 
  /* background-image: url(''); */
}
.custom-select__option:active {
  background-color: #E6E6E6 !important; /* Change to your desired color */
  color: black !important;         /* Adjust text color if needed */
}

.Mui-TableHeadCell-Content-Labels{
  width: 100%;
}

.Mui-TableHeadCell-Content-Wrapper{
  width:100%
}

section.flex.w-full.lg\:mx-6.md\:mt-3.flex-col.text-sm.text-zinc-950 {
  width: 92%;
}

.css-uqq6zz-MuiFormLabel-root-MuiInputLabel-root, .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input, .css-dahkp-MuiButtonBase-root-MuiMenuItem-root, .css-dahkp-MuiButtonBase-root-MuiMenuItem-root{
  font-size: 13px !important;
}
.font-14-imp{
  font-size:14px !important;
  font-family: "Poppins" !important;
  color: rgb(115 115 115) !important;
}

div.loading {
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99999999999999999;
}

.loading {
  /* height: 200px; */
  position: relative;
  /* border: 3px solid green; */
}

select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  position: relative;
  justify-content: center;
 align-items: center;
}
.tooltip-csv{
  position: relative;
  justify-content: center;
 align-items: center; 
}
.tooltip-csv .tooltip-CSV{
  top: 25px;
  z-index: 1;
  left: 26px;
  position: absolute;
visibility: hidden;
  background-color:  #E0E0E0;
  color: black;
  text-align: center;
  width: 50px;
  border: 1px solid gray;
  font-size: 12px; 
}
.tooltip-csv:hover .tooltip-CSV{
  visibility: visible;
}
.tooltip .tooltip-text {
  top: 25px;
  right: 12px;
  position: absolute;
visibility: hidden;
  background-color:  #E0E0E0;
  color: black;
  text-align: center;
  width: 50px;
  border: 1px solid gray;
  font-size: 12px; 
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
.Invoices-tooltip{
  position: relative;

 
}
.Invoices-tooltip .Invoices-text{
  font-size: 12px; 
  position: absolute;
  visibility: hidden;
  color: black;
  border: 1px solid gray;
  background-color: #E0E0E0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
width: 40px;
cursor: pointer;
margin-top: -8px;
z-index: 1;
margin-left: 80px;
}
@-moz-document url-prefix() {
  .Invoices-tooltip .Invoices-text {
    margin-top: -10px;
    margin-left: 30px;
  }
}
.Invoices-tooltip:hover .Invoices-text{
  visibility: visible;
}
.Budget-tooltip{
  position: relative;
  justify-content: center;
 align-items: center;
}
.Budget-tooltip .Budegt-text {
  font-size: 12px; 
  position: absolute;
  display: flex;
  visibility: hidden;
  background-color: #E0E0E0;
  color: black;
  border: 1px solid gray;
  margin-top: -23px;
  cursor: pointer;
  left: 10px;
  padding: 2px;
}
.Budget-tooltip:hover .Budegt-text{
  visibility: visible;
}
.tooltip-Download {
  position: relative;
  justify-content: center;
 align-items: center; 
}
.tooltip-Download .tooltip-text-Download{
  top: 28px;
  padding: 1px;
  right: 12px;
  position: absolute;
visibility: hidden;
  background-color:  #E0E0E0;
  color: black;
  text-align: center;
  width: 60px;
  border: 1px solid gray;
  font-size: 12px; 
}
.tooltip-Download:hover .tooltip-text-Download{
  visibility: visible;
}